/*
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.

/*
 * Common LP Polyfills
 */
require('@little-phil/frontend/js/polyfills/es7-object');
require('@little-phil/frontend/js/polyfills/intersection-observer');

/*
 * Add other polyfills here
 */
